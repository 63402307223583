import { render, staticRenderFns } from "./DatiClientePerFattura.vue?vue&type=template&id=62dfde04&scoped=true&"
import script from "./DatiClientePerFattura.vue?vue&type=script&lang=js&"
export * from "./DatiClientePerFattura.vue?vue&type=script&lang=js&"
import style0 from "./DatiClientePerFattura.vue?vue&type=style&index=0&id=62dfde04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62dfde04",
  null
  
)


import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QOptionGroup,QInput});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62dfde04')) {
      api.createRecord('62dfde04', component.options)
    } else {
      api.reload('62dfde04', component.options)
    }
    module.hot.accept("./DatiClientePerFattura.vue?vue&type=template&id=62dfde04&scoped=true&", function () {
      api.rerender('62dfde04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Fatture/DatiClientePerFattura.vue"
export default component.exports