var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Dati per emissione fattura"),
      ]),
      _c("br"),
      _vm._v(" Pratica numero " + _vm._s(_vm.guid_pratica) + " "),
      _c("hr"),
    ]),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center q-pl-md" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-pr-md", attrs: { align: "left" } },
        [
          _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
            _vm._v("Dati di Fatturazione"),
          ]),
          _c("br"),
          _vm._l(_vm.elenco_contratti.contratti, function (prodotto, index) {
            return _c("div", { key: index }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered",
                  staticStyle: { "font-size": "18px", width: "100%" },
                },
                [
                  _vm._m(0, true),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(prodotto.label_prodotto) +
                            " - " +
                            _vm._s(prodotto.label_tariffa)
                        ),
                      ]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(_vm._s(prodotto.premio.toFixed(2)) + " €"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Consulenza per intermediazione assicurativa"),
                      ]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(
                          _vm._s(_vm.getConsulenza(prodotto.tariffa)) + " €"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tfoot", [
                    _c("tr", [
                      _vm._m(1, true),
                      _c("td", { attrs: { align: "right" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.getImportoTotale(prodotto.tariffa))
                          ),
                        ]),
                        _vm._v(" €"),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("br"),
            ])
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-pr-md", attrs: { align: "left" } },
        [
          _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
            _vm._v("Dati del Contraente"),
          ]),
          _c(
            "table",
            {
              staticClass: "table table-bordered",
              staticStyle: { "font-size": "18px", width: "100%" },
            },
            [
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c("td", [_vm._v("Nominativo")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.dati_cliente.cognome) +
                          " " +
                          _vm._s(_vm.dati_cliente.nome)
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Tipo persona")]),
                    _c("td", [_vm._v(_vm._s(_vm.dati_cliente.tipo_persona))]),
                  ]),
                  _vm.dati_cliente.tipo_cliente === "persona_fisica"
                    ? _c("tr", [
                        _c("td", [_vm._v("Sesso")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.dati_cliente.sesso) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.dati_cliente.tipo_cliente === "persona_fisica"
                    ? _c("tr", [
                        _c("td", [_vm._v("Codice Fiscale")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.dati_cliente.codice_fiscale) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.dati_cliente.tipo_cliente !== "persona_fisica"
                    ? _c("tr", [
                        _c("td", [_vm._v("Partita IVA")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.dati_cliente.partita_iva) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { staticStyle: { width: "50%" } }, [
                      _vm._v(
                        " Soggetto escluso dall’obbligatorietà della comunicazione PEC (quali imprese individuali, consorzi con attività esterna di cui all’art. 2612 c.c., imprese che non sono costituite in forma societaria come Associazioni/Fondazioni/Enti). Inserire '000000' nel codice univoco destinatario "
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("q-option-group", {
                          attrs: { options: _vm.options },
                          model: {
                            value: _vm.dati_cliente.esente_obbligo_pec,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_cliente,
                                "esente_obbligo_pec",
                                $$v
                              )
                            },
                            expression: "dati_cliente.esente_obbligo_pec",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.dati_cliente.tipo_cliente !== "persona_fisica"
                    ? _c("tr", [
                        _c("td", [_vm._v("Codice Univoco Destinatario")]),
                        _c(
                          "td",
                          [
                            _c("q-input", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                label: "Codice Univoco Destinatario",
                                rules: [
                                  function (val) {
                                    return (
                                      val.length === 7 ||
                                      "Il Codice Univoco Destinatario deve essere lungo 7 caratteri"
                                    )
                                  },
                                ],
                              },
                              model: {
                                value:
                                  _vm.dati_cliente.codice_univoco_destinatario,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dati_cliente,
                                    "codice_univoco_destinatario",
                                    $$v
                                  )
                                },
                                expression:
                                  "dati_cliente.codice_univoco_destinatario",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.dati_cliente.tipo_cliente === "persona_fisica"
                    ? [
                        _vm.dati_cliente.tipo_cliente !== "persona_fisica"
                          ? _c("tr", [
                              _c("td", [_vm._v("Data di nascita")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.dati_cliente.data_nascita) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.dati_cliente.tipo_cliente !== "persona_fisica"
                          ? _c("tr", [
                              _c("td", [_vm._v("Comune di nascita")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.dati_cliente.comune_nascita) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.dati_cliente.tipo_cliente !== "persona_fisica"
                          ? _c("tr", [
                              _c("td", [_vm._v("Provincia di nascita")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.dati_cliente.provincia_nascita) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c("tr", [
                    _c("td", [_vm._v("Professione")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.professione) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Indirizzo di residenza/Sede legale")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.dati_cliente.indirizzo_residenza) + " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Comune di residenza/Sede legale")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.comune_residenza) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("CAP di residenza/Sede legale")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.cap_residenza) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Provincia di residenza/Sede legale")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.dati_cliente.provincia_residenza) + " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Indirizzo recapito")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.indirizzo_recapito) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Comune recapito")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.comune_recapito) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("CAP recapito")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.cap_recapito) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Provincia recapito")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.provincia_recapito) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Telefono")]),
                    _c("td", [_vm._v(_vm._s(_vm.dati_cliente.telefono) + " ")]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Cellulare")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.dati_cliente.cellulare) + " "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Email")]),
                    _c("td", [_vm._v(_vm._s(_vm.dati_cliente.email) + " ")]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Email PEC")]),
                    _c(
                      "td",
                      [
                        _c("q-input", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            label: "Email PEC",
                            rules: [
                              function (val) {
                                return (
                                  val.length > 0 ||
                                  "Indirizzo di posta elettronica PEC obbligatorio"
                                )
                              },
                            ],
                          },
                          model: {
                            value: _vm.dati_cliente.pec,
                            callback: function ($$v) {
                              _vm.$set(_vm.dati_cliente, "pec", $$v)
                            },
                            expression: "dati_cliente.pec",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._m(2),
      _c(
        "div",
        {
          staticClass: "col-md-12 col-12 q-pr-md row justify-center",
          attrs: { align: "center" },
        },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
              icon_align: "left",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked()
              },
            },
          }),
          _c("QQButton", {
            attrs: {
              label: "Registra",
              color: "blue-grey",
              icon: "mdi-arrow-right-bold",
              size: "md",
              icon_align: "right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onAvantiClicked()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { align: "left" } }, [_vm._v("Garanzia concessa")]),
        _c("th", [_vm._v("Importo")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "right" } }, [
      _c("strong", [_vm._v("TOTALE:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }